import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeAnchorHeadingSkeleton } from "./TypeAnchorHeading";
import type { TypeBannerComponentSkeleton } from "./TypeBannerComponent";
import type { TypeCardRowComponentSkeleton } from "./TypeCardRowComponent";
import type { TypeFaqComponentSkeleton } from "./TypeFaqComponent";
import type { TypeRichTextComponentSkeleton } from "./TypeRichTextComponent";
import type { TypeSeoSkeleton } from "./TypeSeo";

/**
 * Fields type definition for content type 'TypeCategoryPage'
 * @name TypeCategoryPageFields
 * @type {TypeCategoryPageFields}
 * @memberof TypeCategoryPage
 */
export interface TypeCategoryPageFields {
    /**
     * Field type definition for field 'title' (Title)
     * @name Title
     * @localized false
     */
    title: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'menuTitle' (Menu Title)
     * @name Menu Title
     * @localized false
     */
    menuTitle?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'seoMetadata' (SEO Metadata)
     * @name SEO Metadata
     * @localized false
     */
    seoMetadata?: EntryFieldTypes.EntryLink<TypeSeoSkeleton>;
    /**
     * Field type definition for field 'slug' (Slug)
     * @name Slug
     * @localized false
     */
    slug: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'header' (Header)
     * @name Header
     * @localized false
     */
    header: EntryFieldTypes.EntryLink<TypeBannerComponentSkeleton>;
    /**
     * Field type definition for field 'content' (Content)
     * @name Content
     * @localized false
     */
    content: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeAnchorHeadingSkeleton | TypeBannerComponentSkeleton | TypeCardRowComponentSkeleton | TypeFaqComponentSkeleton | TypeRichTextComponentSkeleton>>;
}

/**
 * Entry skeleton type definition for content type 'categoryPage' (📄 Category Page)
 * @name TypeCategoryPageSkeleton
 * @type {TypeCategoryPageSkeleton}
 * @author 4DB2lvMevuhSxpDRuXX3Hr
 * @since 2023-09-14T11:00:35.954Z
 * @version 117
 */
export type TypeCategoryPageSkeleton = EntrySkeletonType<TypeCategoryPageFields, "categoryPage">;
/**
 * Entry type definition for content type 'categoryPage' (📄 Category Page)
 * @name TypeCategoryPage
 * @type {TypeCategoryPage}
 * @author 4DB2lvMevuhSxpDRuXX3Hr
 * @since 2023-09-14T11:00:35.954Z
 * @version 117
 */
export type TypeCategoryPage<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeCategoryPageSkeleton, Modifiers, Locales>;

export function isTypeCategoryPage<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeCategoryPage<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'categoryPage'
}
