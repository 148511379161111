import { SafeParseSuccess, TypeOf, z, ZodError, ZodObject } from "zod";

const onValidationError = (error: ZodError) => {
  console.error("❌ Invalid environment variables:", error.flatten().fieldErrors);
  throw new Error("Invalid environment variables");
};

const runtimeEnv = {
  // Trustpilot
  NEXT_PUBLIC_TP_BUSINESS_UNIT_ID: process.env.NEXT_PUBLIC_TP_BUSINESS_UNIT_ID,

  // Algolia
  NEXT_PUBLIC_ALGOLIA_INDEX: process.env.NEXT_PUBLIC_ALGOLIA_INDEX,
  NEXT_PUBLIC_ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,

  // Vercel KV storage
  REDIRECTS_KV_URL: process.env.REDIRECTS_KV_URL,
  REDIRECTS_KV_REST_API_TOKEN: process.env.REDIRECTS_KV_REST_API_TOKEN,
  REDIRECTS_KV_REST_API_URL: process.env.REDIRECTS_KV_REST_API_URL,
  REDIRECTS_KV_REST_API_READ_ONLY_TOKEN: process.env.REDIRECTS_KV_REST_API_READ_ONLY_TOKEN,

  // Talon One
  TALON_ONE_LOYALTY_ID: process.env.TALON_ONE_LOYALTY_ID,
  TALON_ONE_URL: process.env.TALON_ONE_URL,
  TALON_ONE_API_KEY: process.env.TALON_ONE_API_KEY,

  // CommerceTools
  CT_CLIENT_ID: process.env.CT_CLIENT_ID,
  CT_CLIENT_SECRET: process.env.CT_CLIENT_SECRET,
  EXEMPT_TAX_CATEGORY_ID: process.env.EXEMPT_TAX_CATEGORY_ID,
  NEXT_PUBLIC_CT_PROJECT_KEY: process.env.NEXT_PUBLIC_CT_PROJECT_KEY,
  NEXT_PUBLIC_CT_API_URL: process.env.NEXT_PUBLIC_CT_API_URL,
  NEXT_PUBLIC_CT_AUTH_URL: process.env.NEXT_PUBLIC_CT_AUTH_URL,
  NEXT_PUBLIC_STANDARD_TAX_CATEGORY_ID: process.env.NEXT_PUBLIC_STANDARD_TAX_CATEGORY_ID,

  // Stripe
  STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
  NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY,

  // AWS S3
  S3_ACCESS_KEY: process.env.S3_ACCESS_KEY,
  S3_SECRET_KEY: process.env.S3_SECRET_KEY,
  S3_REGION: process.env.S3_REGION,
  S3_BUCKET_NAME: process.env.S3_BUCKET_NAME,

  // Klaviyo
  KLAVIYO_NEWSLETTER_LIST_ID: process.env.KLAVIYO_NEWSLETTER_LIST_ID,
  KLAVIYO_PRIVATE_KEY: process.env.KLAVIYO_PRIVATE_KEY,
  NEXT_PUBLIC_KLAVIYO_SUBSCRIPTION_FORM_ID: process.env.NEXT_PUBLIC_KLAVIYO_SUBSCRIPTION_FORM_ID,
  NEXT_PUBLIC_KLAVIYO_PUBLIC_API_KEY: process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_API_KEY,

  // Contentful
  CONTENTFUL_SPACE_ID: process.env.CONTENTFUL_SPACE_ID,
  CONTENTFUL_ENVIRONMENT: process.env.CONTENTFUL_ENVIRONMENT,
  CONTENTFUL_ACCESS_TOKEN: process.env.CONTENTFUL_ACCESS_TOKEN,
  CONTENTFUL_PREVIEW_ACCESS_TOKEN: process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN,

  // NextAuth
  NEXTAUTH_URL: process.env.NEXTAUTH_URL,
  NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,

  // Next (Vercel)
  NEXT_PUBLIC_ENABLE_ERROR_BOUNDARY: process.env.NEXT_PUBLIC_ENABLE_ERROR_BOUNDARY,
  NEXT_PUBLIC_BASE_URL: process.env.NEXT_PUBLIC_VERCEL_URL
    ? `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`
    : "http://localhost:3000",

  // Sentry
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Product-API
  NEXT_PUBLIC_WTTB_API: process.env.NEXT_PUBLIC_WTTB_API,

  // Google
  GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
  NEXT_PUBLIC_GOOGLE_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  NEXT_PUBLIC_GA_ID: process.env.NEXT_PUBLIC_GA_ID,

  // Cookies
  NEXT_PUBLIC_COOKIEYES_KEY: process.env.NEXT_PUBLIC_COOKIEYES_KEY,

  // Zendesk
  NEXT_PUBLIC_ZENDESK_KEY: process.env.NEXT_PUBLIC_ZENDESK_KEY,

  // Zendesk
  NEXT_PUBLIC_BASELIME_API_KEY: process.env.NEXT_PUBLIC_BASELIME_API_KEY,

  // Userflow
  NEXT_PUBLIC_USERFLOW_TOKEN: process.env.NEXT_PUBLIC_USERFLOW_TOKEN,

  // Custom variables
  NEXT_PUBLIC_STAPLED_BOOKLETS_SKU: process.env.NEXT_PUBLIC_STAPLED_BOOKLETS_SKU,
};

const validators = {
  // Variables that can be available only on server
  server: {
    // Vercel KV storage
    REDIRECTS_KV_URL: z.string().url(),
    REDIRECTS_KV_REST_API_TOKEN: z.string().min(1),
    REDIRECTS_KV_REST_API_URL: z.string().url(),
    REDIRECTS_KV_REST_API_READ_ONLY_TOKEN: z.string().min(1),

    // Talon One
    TALON_ONE_LOYALTY_ID: z.string().default("3"),
    TALON_ONE_URL: z.string().default("https://wttb.europe-west2.talon.one"),
    TALON_ONE_API_KEY: z.string().min(1),

    // CommerceTools
    CT_CLIENT_ID: z.string().min(1),
    CT_CLIENT_SECRET: z.string().min(1),
    EXEMPT_TAX_CATEGORY_ID: z.string().min(1),

    // Stripe
    STRIPE_SECRET_KEY: z.string().min(1),

    // AWS S3
    S3_ACCESS_KEY: z.string().min(1),
    S3_SECRET_KEY: z.string().min(1),
    S3_REGION: z.string().min(1),
    S3_BUCKET_NAME: z.string().min(1),

    // Klaviyo
    KLAVIYO_NEWSLETTER_LIST_ID: z.string().min(1),
    KLAVIYO_PRIVATE_KEY: z.string().min(1),

    // Contentful
    CONTENTFUL_SPACE_ID: z.string().min(1),
    CONTENTFUL_ENVIRONMENT: z.string().default("staging"),
    CONTENTFUL_ACCESS_TOKEN: z.string().min(1),
    CONTENTFUL_PREVIEW_ACCESS_TOKEN: z.string().min(1),

    // NextAuth
    NEXTAUTH_URL: z.string().default("http://localhost:3000"),
    NEXTAUTH_SECRET: z.string().default("myencryptionsecret"),

    // Baselime
    BASELIME_API_KEY: z.string().optional(),
  },

  // Variables that can be available on server and client
  client: {
    // Algolia
    NEXT_PUBLIC_ALGOLIA_INDEX: z.string().default("staging_index"),
    NEXT_PUBLIC_ALGOLIA_APP_ID: z.string().min(1),
    NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY: z.string().min(1),

    // Trustpilot
    NEXT_PUBLIC_TP_BUSINESS_UNIT_ID: z.string().min(1),

    // Klaviyo
    NEXT_PUBLIC_KLAVIYO_SUBSCRIPTION_FORM_ID: z.string().min(1),
    NEXT_PUBLIC_KLAVIYO_PUBLIC_API_KEY: z.string().min(1),

    // Stripe
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: z.string().min(1),

    // CommerceTools
    NEXT_PUBLIC_CT_PROJECT_KEY: z.string().min(1),
    NEXT_PUBLIC_CT_API_URL: z.string().url(),
    NEXT_PUBLIC_CT_AUTH_URL: z.string().url(),
    NEXT_PUBLIC_STANDARD_TAX_CATEGORY_ID: z.string().min(1),

    // NEXT (Vercel)
    NEXT_PUBLIC_ENABLE_ERROR_BOUNDARY: z.string().default("false"),
    NEXT_PUBLIC_BASE_URL: z.string(),

    // Sentry
    NEXT_PUBLIC_SENTRY_DSN: z.string().min(1),

    // Product-API
    NEXT_PUBLIC_WTTB_API: z.string().default("http://localhost:3001"),

    // Google
    NEXT_PUBLIC_GOOGLE_API_KEY: z.string().default(""),
    GOOGLE_API_KEY: z.string().default(""),
    NEXT_PUBLIC_GTM_ID: z.string().default(""),
    NEXT_PUBLIC_GA_ID: z.string().default(""),

    // Cookies
    NEXT_PUBLIC_COOKIEYES_KEY: z.string().default(""),

    // Zendesk
    NEXT_PUBLIC_ZENDESK_KEY: z.string().min(1),

    // Baselime
    NEXT_PUBLIC_BASELIME_API_KEY: z.string().optional(),

    // Userflow
    NEXT_PUBLIC_USERFLOW_TOKEN: z.string().optional(),

    // Custom variables
    NEXT_PUBLIC_STAPLED_BOOKLETS_SKU: z.string().default("booklets-stapled"),
  },
};

type PositiveParseResult = SafeParseSuccess<
  TypeOf<ZodObject<typeof validators.client>> & TypeOf<ZodObject<typeof validators.server>>
>["data"];

const validateConfig = (): PositiveParseResult => {
  // Skip validation
  const skipValidation = !!process.env.SKIP_ENV_VALIDATION;

  if (skipValidation) return runtimeEnv as PositiveParseResult;

  const isServer = typeof window === "undefined";

  const parsed = isServer
    ? z.object({ ...validators.server, ...validators.client }).safeParse(runtimeEnv)
    : z.object(validators.client).safeParse(runtimeEnv);

  if (!parsed.success) {
    return onValidationError(parsed.error);
  }

  return parsed.data as PositiveParseResult;
};

export const config = validateConfig();
