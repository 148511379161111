import type { ChainModifiers, Entry, EntryFieldTypes, EntrySkeletonType, LocaleCode } from "contentful";
import type { TypeBlogPageSkeleton } from "./TypeBlogPage";
import type { TypeCategoryPageSkeleton } from "./TypeCategoryPage";
import type { TypeContactUsPageSkeleton } from "./TypeContactUsPage";
import type { TypeContentPageSkeleton } from "./TypeContentPage";
import type { TypeExternalLinkSkeleton } from "./TypeExternalLink";
import type { TypeProductPageSkeleton } from "./TypeProductPage";

/**
 * Fields type definition for content type 'TypeNavSecondLevel'
 * @name TypeNavSecondLevelFields
 * @type {TypeNavSecondLevelFields}
 * @memberof TypeNavSecondLevel
 */
export interface TypeNavSecondLevelFields {
    /**
     * Field type definition for field 'internalName' (Internal Name)
     * @name Internal Name
     * @localized false
     */
    internalName?: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'name' (Name)
     * @name Name
     * @localized true
     */
    name: EntryFieldTypes.Symbol;
    /**
     * Field type definition for field 'targetPage' (Target Page)
     * @name Target Page
     * @localized false
     */
    targetPage?: EntryFieldTypes.EntryLink<TypeBlogPageSkeleton | TypeCategoryPageSkeleton | TypeContactUsPageSkeleton | TypeContentPageSkeleton | TypeProductPageSkeleton>;
    /**
     * Field type definition for field 'links' (Links)
     * @name Links
     * @localized false
     */
    links?: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<TypeBlogPageSkeleton | TypeCategoryPageSkeleton | TypeContactUsPageSkeleton | TypeContentPageSkeleton | TypeExternalLinkSkeleton | TypeProductPageSkeleton>>;
}

/**
 * Entry skeleton type definition for content type 'navSecondLevel' (🎛 Nav Second Level)
 * @name TypeNavSecondLevelSkeleton
 * @type {TypeNavSecondLevelSkeleton}
 * @author 4cbPwqAt5JzV5Z1U9eQ7iG
 * @since 2024-08-14T10:23:59.367Z
 * @version 7
 */
export type TypeNavSecondLevelSkeleton = EntrySkeletonType<TypeNavSecondLevelFields, "navSecondLevel">;
/**
 * Entry type definition for content type 'navSecondLevel' (🎛 Nav Second Level)
 * @name TypeNavSecondLevel
 * @type {TypeNavSecondLevel}
 * @author 4cbPwqAt5JzV5Z1U9eQ7iG
 * @since 2024-08-14T10:23:59.367Z
 * @version 7
 */
export type TypeNavSecondLevel<Modifiers extends ChainModifiers, Locales extends LocaleCode = LocaleCode> = Entry<TypeNavSecondLevelSkeleton, Modifiers, Locales>;

export function isTypeNavSecondLevel<Modifiers extends ChainModifiers, Locales extends LocaleCode>(entry: Entry<EntrySkeletonType, Modifiers, Locales>): entry is TypeNavSecondLevel<Modifiers, Locales> {
    return entry.sys.contentType.sys.id === 'navSecondLevel'
}
