import { Asset, Entry, EntrySkeletonType } from "contentful";

import {
  TypeBlogArticlePageSkeleton,
  TypeBlogPageSkeleton,
  TypeCategoryPageSkeleton,
  TypeContactUsPageSkeleton,
  TypeContentPageSkeleton,
  TypeContentWithFormPageSkeleton,
  TypeHomePageSkeleton,
  TypeProductAttributeInfoSkeleton,
  TypeProductAttributesInfoConfigSkeleton,
  TypeProductPageSkeleton,
} from "./ts-generated";

export type CTF_MODIFIER = "WITHOUT_UNRESOLVABLE_LINKS";

export type CtfAsset = Asset<CTF_MODIFIER>;
export type CtfEntry<T extends EntrySkeletonType = EntrySkeletonType> = Entry<T, CTF_MODIFIER>;

// todo: fix me - this isn't a complete list, yet some page types don't have `menuTitle` field
export type TypePageSkeleton =
  | TypeCategoryPageSkeleton
  | TypeContentPageSkeleton
  | TypeProductPageSkeleton
  | TypeContactUsPageSkeleton
  | TypeContentWithFormPageSkeleton;

export type BlogPage = CtfEntry<TypeBlogPageSkeleton>;
export type BlogArticlePage = CtfEntry<TypeBlogArticlePageSkeleton>;
export type CategoryPage = CtfEntry<TypeCategoryPageSkeleton>;
export type ContentPage = CtfEntry<TypeContentPageSkeleton>;
export type ContentWithFormPage = CtfEntry<TypeContentWithFormPageSkeleton>;
export type HomePage = CtfEntry<TypeHomePageSkeleton>;
export type ContactUsPage = CtfEntry<TypeContactUsPageSkeleton>;
export type ProductPage = CtfEntry<TypeProductPageSkeleton>;
export type ProductAttributesInfoConfig = CtfEntry<TypeProductAttributesInfoConfigSkeleton>;
export type ProductAttributeInfo = CtfEntry<TypeProductAttributeInfoSkeleton>;

export * from "./ts-generated";
