"use client";

import {
  Cart,
  CentPrecisionMoney,
  Customer,
  DiscountCodeInfo,
  LineItem,
  Order,
} from "@commercetools/platform-sdk";
import { sendGTMEvent } from "@next/third-parties/google";

import { ProductPage } from "@/clients/contentful";
import { transformCents } from "@/features/product/hooks/use-price";
import {
  AddToCartEvent,
  BeginCheckoutEvent,
  LoginEvent,
  PurchaseEvent,
  RemoveFromCartEvent,
  ViewCartEvent,
  ViewItemEvent,
} from "./types";

/**
 * Track add to cart event
 *
 * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_to_cart}
 */
export function trackAddToCartEvent({ lineItem, cart }: { lineItem: LineItem; cart: Cart }) {
  const data: AddToCartEvent = {
    event: "add_to_cart",
    ...createBaseEventFields({ cart }),
    items: [formatLineItem({ lineItem })],
  };

  sendGTMEvent(data);
}

/**
 * Track remove from cart event
 *
 * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#remove_from_cart}
 */
export function trackRemoveFromCartEvent({ lineItem, cart }: { lineItem: LineItem; cart: Cart }) {
  const data: RemoveFromCartEvent = {
    event: "remove_from_cart",
    ...createBaseEventFields({ cart }),
    items: [formatLineItem({ lineItem })],
  };

  sendGTMEvent(data);
}

/**
 * Track view cart event
 *
 * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_cart}
 */
export function trackViewCartEvent({ cart }: { cart: Cart }) {
  const data: ViewCartEvent = {
    event: "view_cart",
    ...createBaseEventFields({ cart }),
    items: cart.lineItems.map((lineItem) => formatLineItem({ lineItem })),
  };

  sendGTMEvent(data);
}

/**
 * Track view item event
 *
 * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#view_item}
 */
export function trackViewItemEvent({
  product,
  price,
}: {
  product: ProductPage;
  price: CentPrecisionMoney;
}) {
  const value = transformCents(price);
  const data: ViewItemEvent = {
    event: "view_item",
    currency: price.currencyCode,
    value,
    items: [
      {
        item_id: product.fields.sku!,
        item_name: product.fields.title,
        price: value,
      },
    ],
  };

  sendGTMEvent(data);
}

/**
 * Track purchase event
 *
 * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#purchase}
 */
export function trackPurchaseEvent({ order }: { order: Order }) {
  const data: PurchaseEvent = {
    event: "purchase",
    transaction_id: order.id,
    ...createBaseEventFields({ order }),
    items: order.lineItems.map((lineItem) => formatLineItem({ lineItem })),
  };

  sendGTMEvent(data);
}

/**
 * Track begin checkout event
 *
 * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#begin_checkout}
 */
export function trackCheckoutEvent({ cart }: { cart: Cart }) {
  const data: BeginCheckoutEvent = {
    event: "begin_checkout",
    ...createBaseEventFields({ cart }),
    items: cart.lineItems.map((lineItem) => formatLineItem({ lineItem })),
  };

  sendGTMEvent(data);
}

/**
 * Track login event
 *
 * @see {@link https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#login}
 */
export function trackLoginEvent({ user }: { user: Customer }) {
  const data: LoginEvent = {
    event: "login",
    client_id: user.id,
  };

  sendGTMEvent(data);
}

/// Utility functions

function createBaseEventFields({ order, cart }: { order?: Order; cart?: Cart }) {
  const coupon = (order ?? cart)?.discountCodes?.map(
    (discountCodeInfo: DiscountCodeInfo) => discountCodeInfo.discountCode.obj?.code!,
  );
  return {
    currency: (order ?? cart)!.totalPrice.currencyCode!,
    value: transformCents((order ?? cart)!.totalPrice), // same as valueNet
    valueNet: transformCents((order ?? cart)!.totalPrice),
    valueGross: transformCents((order ?? cart)!.taxedPrice?.totalGross!),
    totalTax: transformCents((order ?? cart)!.taxedPrice?.totalTax!),
    totalShipping: (order ?? cart)!.shipping?.reduce(
      (acc, shipping) => acc + transformCents(shipping.shippingInfo.price),
      0,
    ),
    coupon,
    client_id: (order ?? cart)!.customerId,
  };
}

function formatLineItem({ lineItem }: { lineItem: LineItem }) {
  return {
    item_id: lineItem.id,
    item_name: lineItem.name["en-GB"],
    affiliation: "Where The Trade Buys",
    item_category: lineItem.productType?.obj?.name,
    item_variant: lineItem.variant?.sku,
    price: transformCents(lineItem.price.value), // same as unitPriceNet
    unitPriceNet: transformCents(lineItem.price.value),
    quantity: lineItem.quantity,
  };
}
