"use client";

import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { Fragment } from "react";

import { useUI } from "@/common/contexts/ui.context";
import { Cart } from "@/features/basket/components/cart";
import { basketTestIds } from "@/features/basket/test-ids";

export function CartDrawer() {
  const { displayCart, closeCart } = useUI();

  return (
    <Transition show={displayCart}>
      <Dialog onClose={closeCart} className="relative z-[999999]">
        <TransitionChild
          as={Fragment}
          enter="transition-all ease-in-out duration-300"
          enterFrom="opacity-0 backdrop-blur-none"
          enterTo="opacity-100 backdrop-blur-[.5px]"
          leave="transition-all ease-in-out duration-200"
          leaveFrom="opacity-100 backdrop-blur-[.5px]"
          leaveTo="opacity-0 backdrop-blur-none"
        >
          <div className="fixed inset-0 bg-black/40" aria-hidden="true" />
        </TransitionChild>
        <TransitionChild
          as={Fragment}
          enter="transition-all ease-in-out duration-300"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition-all ease-in-out duration-200"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <DialogPanel
            data-testid={basketTestIds.basketRightPanel}
            className="border-grey-30 fixed inset-y-0 right-0 flex size-full flex-col border-l bg-white p-0 text-black md:w-[450px]"
          >
            <Cart />
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
}
